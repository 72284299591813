// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Impressum',
  slug: '/impressum',
  abstract:
    'Since 1988 Prisma Books of Auroville publishes good old-fashioned, beautifully curated books. Prisma Books offers full publishing services in design, editorial, printing, artwork, illustrations, photography, digital media, exhibition and curatorship.',
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'Impressum', slug: '/impressum' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
// export const query = graphql`
//   query {
//     launch: file(relativePath: { eq: "launch.jpg" }) {
//       ...max900
//     }
//   }
// `

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <StandardPageWrapper className="" pageSchema={pageSchema} {...props}>
    <h1>Impressum</h1>
    <p>
      <i>
        “At Auroville nothing belongs to anyone in particular. All is collective
        property. To be utilised with my blessings for the welfare of all.”
      </i>
      <br />- Mother, 14.5.70
    </p>
    <ul>
      <li>Compiled by Martin Sobieroj and Franz Fassbender</li>
      <li>First edition 2015, Second revised edition 2021</li>
      <li>Print ISBN: 978-81-939500-3-6</li>
      <li>Designed & Published by Prisma</li>
      <li>
        Publisher's address: Aurelec / Prayogashala, Auroville 605101 Tamil
        Nadu, India
      </li>
      <li>Publisher's email: prisma@auroville.org.in</li>
      <li>Publisher's website: https://books.prisma.haus</li>
      <li>Book designed and published by Prisma Books</li>
      <li>Web Book handcrafted by Prisma Design</li>
    </ul>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
